// Listo
import React from 'react';

import './styles.css';

export default function Loading() {
  return (
    <div className='loading'>
      <div className="spinner"></div>
      </div>
  );
}

