export const styles = {

    errorError: {
        fontSize: '1.2rem',
        textAlign: 'center',
        color: 'red',
        margin: '1rem 0',
    },

    errorMessage: {
        margin: '0 1rem',
    },


}