// import { theme } from '../../styles';

export const styles = {

  footerRoot: {
    backgroundColor: 'black',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    width: '100%',
    height: '50px',
    color: 'white',
  },

      footerToolbar: {
    minHeight: 50,
  },

}